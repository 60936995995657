import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BadgeCategory from "./BadgeCategory"
import ConvertTimestamp from "./ConvertTimestamp"
import PlaceholderImage from "./PlaceholderImage"
import Card from "react-bootstrap/Card"

export default function ArticleHero({ article }) {
  const { abstract, category, img, release, slug, title } = article.frontmatter

  return (
    <Card as="article" className="border bg-light h-100">
      <div className="position-relative">
        <div
          className="position-absolute h5"
          style={{
            top: "0.5rem",
            right: "0.75rem",
            zIndex: 1000,
          }}
        >
          <BadgeCategory category={category} />
        </div>
      </div>
      <Link to={`/archiv/${slug}`}>
        {img ? (
          <GatsbyImage
            placeholder="blurred"
            className="img-fluid rounded"
            image={getImage(img)}
            alt={abstract}
          />
        ) : (
          <PlaceholderImage alt={`Bild zum Artikel: ${title}`} />
        )}
      </Link>
      <Card.Body className="text-black-50">
        <ConvertTimestamp date={release} lang="de" />
        <Card.Title as="h1" className="font-weight-bold mt-lg-2 mt-xl-3">
          <Link className="text-decoration-none" to={`/archiv/${slug}`}>
            {title}
          </Link>
        </Card.Title>
        <Card.Text as="p" className="lh-text text-dark">
          {abstract}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
