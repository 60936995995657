import { useStaticQuery, graphql } from "gatsby"

export const useLatestKartsportArticles = () => {
  const articles = useStaticQuery(graphql`
    query articlesKartsport {
      allMarkdownRemark(
        sort: { fields: frontmatter___release, order: DESC }
        limit: 4
        filter: {
          frontmatter: {
            category: { eq: "Kartsport" }
            type: { eq: "article" }
          }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              release
              abstract
              category
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            timeToRead
          }
        }
      }
    }
  `)
  return articles.allMarkdownRemark.edges
}
