import React from "react"
import Container from "react-bootstrap/Container"
import { ExternalLink } from "./ui/ExternalLink"

export default function AdContainer({ src, alt = "", link }) {
  return (
    <Container className="d-flex justify-content-center my-5 d-print-none">
      <ExternalLink href={link}>
        <img src={src} alt={alt} className="img-fluid" />
      </ExternalLink>
    </Container>
  )
}
