import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import JumbotronFluid from "../components/JumbotronFluid"
import Layout from "../components/Layout"
import InfoSection from "../components/InfoSection"
import AdContainer from "../components/AdContainer"
import Seo from "../components/Seo"
import { ArrowRight } from "react-bootstrap-icons"
import { useHeroArticle } from "../hooks/useHeroArticle"
import { useLatestKartsportArticles } from "../hooks/useLatestKartsportArticles"
import { useLatestArticles } from "../hooks/useLatestArticles"
import SectionDivider from "../components/SectionDivider"
import ArticleCard from "../components/ArticleCard"
import ArticleHero from "../components/ArticleHero"

export const Head = () => {
  return <Seo customTitle="Hauptseite" />
}

export default function Index() {
  const hero = useHeroArticle()
  const articles = useLatestArticles()
  const articlesKartsport = useLatestKartsportArticles()

  return (
    <Layout>
      <AdContainer
        src="./ads/autodoc-banner.gif"
        alt="Auf der Website autodoc.de können Sie schnell auswählen, was Ihr Auto braucht"
        link="https://www.autodoc.de/"
      />
      <Container className="my-5">
        <SectionDivider title="Aktuell" headingSize={3} id="artikel" />
        <Row className="mb-lg-5">
          <Col sm={12} lg={6}>
            <ArticleHero article={hero} />
          </Col>
          <Col sm={12} lg={6} className="mt-5 mt-lg-0">
            <Row xs={1} sm={2}>
              {articles.map((article, index) => (
                <Col key={index}>
                  <ArticleCard
                    articleData={article}
                    i={index}
                    headings={true}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <SectionDivider title="Thema: Kartsport" headingSize={3} />
        <Row>
          {articlesKartsport.map((article, index) => (
            <Col key={index}>
              <ArticleCard articleData={article} i={index} />
            </Col>
          ))}
        </Row>
        <SectionDivider />
        <Link
          className="h6 d-flex align-items-center text-decoration-none"
          to="/archiv"
        >
          <ArrowRight className="mr-2" /> Weitere Artikel finden Sie in unserem
          Archiv...
        </Link>
      </Container>
      <AdContainer
        src="./ads/autoersatzteile.jpg"
        alt="Auf der Seite autoersatzteile.de findet man bequem alles rund ums Thema Auto"
        link="https://www.autoersatzteile.de/"
      />
      <JumbotronFluid backgroundColor="primary" textColor="white">
        <Row className="text-center text-md-left">
          <Col className="mb-5 mb-md-0" md={6} lg={5} xl={4}>
            <p className="h3">
              <span
                className="font-weight-light text-monospace d-block"
                style={{ fontSize: "10rem" }}
              >
                {new Date().getFullYear() - 1930}
              </span>
              Jahre Motorsport
            </p>
          </Col>
          <Col>
            <p className="h5 lh-text">
              Seit 1930 engagieren sich die MotorSportFreunde Plettenberg in den
              Bereichen Kartslalom, Oldtimer und Touristik.
            </p>
            <p className="lh-text my-5 my-lg-4 my-xl-5">
              Alljährlich findet ein Vorlauf zur deutschen Meisterschaft im
              Jugendkartslalom in Plettenberg statt. Zudem werden regelmäßig
              Veranstaltungen rund um Oldtimer und der dazu gehörigen
              Oldtimerrallye organisiert.
            </p>
            <ButtonGroup
              className="d-print-none"
              aria-label="Weiterführende Links"
            >
              <Link to="/verein">
                <Button variant="light">Über den Verein</Button>
              </Link>
              <Link to="/verein/historie">
                <Button className="ml-2" variant="outline-light">
                  Historie
                </Button>
              </Link>
            </ButtonGroup>
          </Col>
        </Row>
      </JumbotronFluid>
      <Container>
        <AdContainer
          src="./ads/pkwteile.png"
          alt="Wir kooperieren mit der Seite www.pkwteile.de"
          link="https://www.pkwteile.de/"
        />
        <InfoSection />
      </Container>
    </Layout>
  )
}
