import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BadgeCategory from "./BadgeCategory"
import ConvertTimestamp from "./ConvertTimestamp"
import PlaceholderImage from "./PlaceholderImage"
import Card from "react-bootstrap/Card"

export default function ArticleCard({ articleData, i, headings = false }) {
  const article = articleData
  const index = i

  const { abstract, category, img, release, slug, title } =
    article.node.frontmatter

  return (
    <Card as="article" className="border-0 mb-3 mb-sm-2 mb-lg-3 h-100">
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{
            top: "0.25rem",
            right: "0.5rem",
            zIndex: 1000,
          }}
        >
          <BadgeCategory category={category} />
        </div>
        <Link to={`/archiv/${slug}`}>
          {img ? (
            <GatsbyImage
              placeholder="blurred"
              className="img-fluid rounded"
              image={getImage(img)}
              alt={abstract}
            />
          ) : (
            <PlaceholderImage />
          )}
        </Link>
      </div>
      <Card.Body className="text-black-50 mt-xl-3">
        <small className="d-block">
          <ConvertTimestamp date={release} lang="de" />
        </small>
        <Link to={`/archiv/${slug}`} className="text-decoration-none">
          {headings ? (
            <Card.Title
              as={`h${index + 2}`}
              className="h5 font-weight-bold mt-lg-2 mb-4 mb-xl-3"
            >
              {title}
            </Card.Title>
          ) : (
            <Card.Title
              className="h5 font-weight-bold mt-lg-2 mb-4 mb-xl-3"
              as="p"
            >
              {title}
            </Card.Title>
          )}
        </Link>
      </Card.Body>
    </Card>
  )
}
