import { useStaticQuery, graphql } from "gatsby"

export const useHeroArticle = () => {
  const hero = useStaticQuery(graphql`
    query hero {
      allMarkdownRemark(
        sort: { fields: frontmatter___release, order: DESC }
        limit: 1
        filter: { frontmatter: { type: { eq: "article" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              release
              abstract
              category
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  return hero.allMarkdownRemark.edges[0].node
}
