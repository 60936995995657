import { useStaticQuery, graphql } from "gatsby"

export const useLatestArticles = () => {
  const articles = useStaticQuery(graphql`
    query indexArticle {
      allMarkdownRemark(
        sort: { fields: frontmatter___release, order: DESC }
        filter: { frontmatter: { type: { eq: "article" } } }
        limit: 4
        skip: 1
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              release
              abstract
              category
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            timeToRead
          }
        }
      }
    }
  `)
  return articles.allMarkdownRemark.edges
}
